import { bodyFreeze, bodyUnfreeze } from "../common/responsive-main";

const ModalPopUp = {
  modalClassShow: "show",
  modalClassIsForced: "forced",
  closeAllModals: function (e) {
    e.preventDefault();
    const modal = ModalPopUp;
    const modalPopupList = document.querySelectorAll(
      `.modal.${modal.modalClassShow}`
    );
    const modalBackdropList = document.querySelectorAll(
      `.modal__backdrop.${modal.modalClassShow}`
    );

    [...modalPopupList, ...modalBackdropList].forEach((modalPopup) => {
      modalPopup.classList.remove(modal.modalClassShow);
    });
    bodyUnfreeze();
  },
  clickOutsideModalHandler: function (e) {
    const modal = ModalPopUp;
    const modalContentContainerList = document.querySelectorAll(
      ".modal__content"
    );
    let closeModal = true;
    for (const modalContentContainer of modalContentContainerList) {
      if (modalContentContainer.contains(e.target)) {
        closeModal = false;
      }
    }
    if (closeModal) {
      modal.closeAllModals(e);
    }
  },
  showForcedModal: function (targetModalId) {
    const modalDialog = document.querySelector(
      `#${targetModalId} .modal__dialog`
    );
    if (modalDialog === null) {
      return;
    }
    modalDialog.classList.add(this.modalClassIsForced);
    this.showModal(targetModalId);
  },
  showModal: function (targetModalId) {
    const modal = document.querySelector(`#${targetModalId} > .modal`);
    if (modal === null) {
      return;
    }
    modal.classList.add(this.modalClassShow);
    document
      .querySelector(`#${targetModalId} > .modal__backdrop`)
      .classList.add(this.modalClassShow);
    bodyFreeze();
  },
  closeModal: function (targetModalId) {
    const lengthOpenedModals = document.querySelectorAll(`.modal.${this.modalClassShow}`).length;
    const modal = document.querySelector(`#${targetModalId} > .modal`);
    if (modal === null) {
      return;
    }
    modal.classList.remove(this.modalClassShow);
    document
        .querySelector(`#${targetModalId} > .modal__backdrop`)
        .classList.remove(this.modalClassShow);
    if (lengthOpenedModals < 2) {
      bodyUnfreeze();
    }
  },
};

document.addEventListener("DOMContentLoaded", () => {
  const defaultModalTriggerList = document.querySelectorAll(
    ".pb-modal-btn-link"
  );
  defaultModalTriggerList.forEach((defaultModalTrigger) => {
    defaultModalTrigger.addEventListener("click", (event) => {
      event.preventDefault();
      event.stopPropagation();
      const modalId = defaultModalTrigger.dataset.pbModalId;
      ModalPopUp.showModal(modalId);
    });
  });

  const modalDialogList = document.querySelectorAll(
    `.modal__dialog:not(.${ModalPopUp.modalClassIsForced})`
  );
  modalDialogList.forEach((modalDialog) => {
    modalDialog.addEventListener("click", (event) => {
      ModalPopUp.clickOutsideModalHandler(event);
    });
  });

  const modalCloseButtonList = document.querySelectorAll(
    "[data-pb-modals-close], .modal__close"
  );
  modalCloseButtonList.forEach((modalCloseButton) => {
    modalCloseButton.addEventListener("click", (event) => {
      event.preventDefault();
      event.stopPropagation();
      const closestDiv = modalCloseButton.closest("div[id]");

      if (closestDiv) {
        const id = closestDiv.getAttribute('id');
        ModalPopUp.closeModal(id);
      }
    });
  });
});

export default ModalPopUp;
